<template>
    <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <Header/>
        <div class="grace-body-1">
            <div>
                <button class="grace-button" type="primary" @click="save">保存</button>
            </div>
            <div>
                <div class="grace-form-label_1s">{{head_portrait}}</div>
                <div class="grace-form-label_ss" style="justify-content: space-between; border: 0px;">
                    <center>
                    <div class="grace-add-list-items grace-add-list-btn">
                        <el-upload
                            class="avatar-uploader"
                            action="#"
                            :http-request="httpRequest"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="avatar" :src="avatar" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                    </center>
                </div>
            </div>
            <div class="grace-form-item grace-border-b grace-border">
                <div class="grace-form-label_1">
                    <span>{{names}}</span>
                </div>
                    <div class="grace-form-label_s">
                    <input type="text" v-model="name" class="grace-form-input" :placeholder="input_name">
                </div>
            </div>
            <div class="grace-form-item grace-border-b">
                <div class="grace-form-label_1">
                    <span>*{{phones}}（852）</span>
                </div>
                    <div class="grace-form-label_s">
                    <input type="number" v-model="phone" class="grace-form-input" oninput="if(value.length>11)value=value.slice(0,11)" :placeholder="input_phone">
                </div>
            </div>
            <div class="grace-form-item grace-border-b">
                <div class="grace-form-label_1">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（86）</span>
                </div>
                    <div class="grace-form-label_s">
                    <input type="number" v-model="phone_86" class="grace-form-input" oninput="if(value.length>11)value=value.slice(0,11)" :placeholder="input_phone">
                </div>
            </div>
            <div class="grace-form-item grace-border-b">
                <div class="grace-form-label_1">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（853）</span>
                </div>
                    <div class="grace-form-label_s">
                    <input type="number" v-model="phone_853" class="grace-form-input" oninput="if(value.length>8)value=value.slice(0,8)" :placeholder="input_phone">
                </div>
            </div>
            <div class="grace-form-item grace-border-b">
                <div class="grace-form-label_1">
                    <span>{{numbers}}</span>
                </div>
                    <div class="grace-form-label_s">
                    <input type="text" v-model="serial_number" class="grace-form-input" :placeholder="input_number">
                </div>
            </div>
            <div class="grace-form-item grace-border-b">
                <div class="grace-form-label_1">
                    <span>*{{emails}}</span>
                </div>
                    <div class="grace-form-label_s">
                    <input type="text" v-model="email" class="grace-form-input" :placeholder="input_email">
                </div>
            </div>

            <div class="grace-form-item grace-border-b" v-if="vip_type">
                <div class="grace-form-label_1">
                    <span>{{gender_s}}</span>
                </div>
                <div>
                   <el-button type="info" :plain="gender == 1?false:''" @click="genders(1)">男</el-button>
                   <el-button type="info" :plain="gender == 2?false:''" @click="genders(2)">女</el-button>
                </div>
            </div>

            <div class="grace-form-item grace-border-b" v-if="vip_type">
                <div class="grace-form-label_1">
                    <span>{{individual_label}}</span>
                </div>
                    <div class="grace-form-label_s">
                    <input type="text" v-model="personal_label" class="grace-form-input" :placeholder="input_individual_label">
                </div>
            </div>
            <div class="grace-form-item grace-border-b" v-if="vip_type">
                <div class="grace-form-label_1">
                    <span>{{individual_resume}}</span>
                </div>
                    <div class="grace-form-label_s">
                    <textarea  v-model="personal_profile" class="grace-form-input" style="height:50px;" :placeholder="input_individual_resume"></textarea>
                </div>
            </div>
            
            <div style="margin-top:60px;">
                <button class="grace-button" type="primary" @click="save">保存</button>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../Header";
import {apiUsersDetail,apiUsers,apiImageUpload} from '@/request/api';
// import qs from 'qs';
export default {
  components:{Header},
  data(){
    return {
        loading:false,
        name:'',
        avatar:'',
        gender:0,
        phone:'',
        phone_86:'',
        phone_853:'',
        personal_label:'',
        personal_profile:'',
        email:'',
        serial_number:'',
        vip_type:true,
        newDataFile:'',
        formData:{},
        lang_id:"",
        head_portrait:'',
        names:'',
        phones:'',
        numbers:'',
        emails:'',
        gender_s:'',
        individual_label:'',
        individual_resume:'',
        
        input_name:'',
        input_phone:'',
        input_number:'',
        input_email:'',
        input_individual_label:'',
        input_individual_resume:'',
    }
  },
  methods:{
      save(){
        let email = this.email;
        let phone = this.phone;
        let phone_86 = this.phone_86;
        let phone_853 = this.phone_853;
        let lang_id = this.lang_id;
        if(!email){
            if(lang_id == 1){
                this.$message.error('請輸入郵件');
            }else{
                this.$message.error('请输入邮件');
            }
            return false;
        }
        var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
        if (!verify.test(email)) {
            if(lang_id == 1){
                this.$message.error('郵箱格式錯誤')
            }else{
                this.$message.error('邮件格式错误')
            }
            return false;
        }

        if(!phone){
            if(lang_id == 1){
                this.$message.error('請填寫號碼')
            }else{
                this.$message.error('请填写号码')
            }
            return false;
        }

        // phone 是number類型 將它轉換成字符串類型 好進行判斷
        if((phone.toString()).length < 8){
            if(lang_id == 1){
                this.$message.error('號碼長度不能小於8位')
            }else{
                this.$message.error('号码长度不能小于8位')
            }
            return false;
        }


        if(phone_86 && (phone_86.toString()).length < 11){
            if(lang_id == 1){
                this.$message.error('電話（86）長度不能小於11位')
            }else{
                this.$message.error('电话（86）长度不能小于11位')
            }
            return false;
        }

        if(phone_853 && (phone_853.toString()).length < 8){
            if(lang_id == 1){
                this.$message.error('電話（853）長度不能小於8位')
            }else{
                this.$message.error('电话（853）长度不能小于8位')
            }
            return false;
        }
        
        let that = this;
        that.loading = true;
        let user_id = this.$store.state.user_id;
        let data = {
           id:user_id,
           name:this.name?this.name:this.$store.state.user_name,
           email:this.email,
           avatar:this.avatar,
           gender:this.gender,
           phone:this.phone,
           phone_86:this.phone_86,
           phone_853:this.phone_853,
           serial_number:this.serial_number,
           personal_label:this.personal_label,
           personal_profile:this.personal_profile,
        }
        apiUsers(user_id,data).then(function(res){
            that.loading = false;
            if(res.code == 200){
                window.localStorage.setItem("user_avatar", res.data.avatar);
                window.localStorage.setItem("user_name", res.data.name);
                window.localStorage.setItem("user_id", res.data.id);
                window.localStorage.setItem("token", res.data.api_token);
                window.localStorage.setItem("month_goal", res.data.month_goal);
                that.$store.state.user_avatar = res.data.avatar;
                that.$store.state.user_name = res.data.name;
                that.$store.state.user_id = res.data.id;
                that.$store.state.login_verify = res.data.api_token;
                that.$store.state.month_goal = res.data.month_goal;
                // location.reload();
                let type = that.$route.query.type;
                that.$message.success('修改成功');
                if(type && type == "vip"){
                    that.$router.go(-1);
                }else{
                    location.reload();
                }
                
            }else{
                that.$message.error(res.message);
            }
            return false;
        }).catch(function(error){
            console.log(error);
            that.loading = false;
        });

        
      },
      genders(val){
          this.gender = val;
      },
      beforeAvatarUpload(file) {
        let types = ['image/png', 'image/jpeg', 'image/jpg'];
        let isUploadFile = types.includes(file['type']);
        let lang_id = this.lang_id;

        // const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isUploadFile) {
         if(lang_id == 1){
             this.$message.error('上傳頭像圖片只能是Image格式!');
         }else{
             this.$message.error('上传头像图片只能是Image格式!');
         }
          return false;
        }
        // if (!isLt2M) {
        //     if(lang_id == 1){
        //         this.$message.error('上傳頭像圖片大小不能超过 2MB!');
        //     }else{
        //         this.$message.error('上传头像图片大小不能超过 2MB!');
        //     }
        //   return false;
        // }
        

        let formData = new FormData();
        formData.append("file", file);
        this.formData = formData;
    
        // return isUploadFile && isLt2M;
        return isUploadFile;
      },

    httpRequest () {
        apiImageUpload(this.formData).then(res=>{
            if(res.code == 200){
                this.avatar = res.data.file_url;
            }else{
                this.$message.error(res.message);
            }
        })
    },
  },
  created(){
    let that = this;
    // 課程=》激活vip 只顯示部分用戶信息可填寫
    let type = this.$route.query.type;

    let lang_id = that.$store.state.lang_id;
    this.lang_id = lang_id;
    if(lang_id == 1){
      this.head_portrait = this.$zh_ft('head_portrait');
      this.names = this.$zh_ft('name');
      this.phones = this.$zh_ft('phone');
      this.numbers = this.$zh_ft('number');
      this.emails = this.$zh_ft('email');
      this.gender_s = this.$zh_ft('gender');
      this.individual_label = this.$zh_ft('individual_label');
      this.individual_resume = this.$zh_ft('individual_resume');

      this.input_name = this.$zh_ft('input_name');
      this.input_phone = this.$zh_ft('input_phone');
      this.input_number = this.$zh_ft('input_number');
      this.input_email = this.$zh_ft('input_email');
      this.input_individual_label = this.$zh_ft('input_individual_label');
      this.input_individual_resume = this.$zh_ft('input_individual_resume');
    }else{
      this.head_portrait = this.$zh_jt('head_portrait');
      this.names = this.$zh_jt('name');
      this.phones = this.$zh_jt('phone');
      this.numbers = this.$zh_jt('number');
      this.emails = this.$zh_jt('email');
      this.gender_s = this.$zh_jt('gender');
      this.individual_label = this.$zh_jt('individual_label');
      this.individual_resume = this.$zh_jt('individual_resume');

      this.input_name = this.$zh_jt('input_name');
      this.input_phone = this.$zh_jt('input_phone');
      this.input_number = this.$zh_jt('input_number');
      this.input_email = this.$zh_jt('input_email');
      this.input_individual_label = this.$zh_jt('input_individual_label');
      this.input_individual_resume = this.$zh_jt('input_individual_resume');
    }

    if(type && type == "vip"){
        this.vip_type = false;
    }
    if(that.$store.state.login_verify){
        let id = that.$store.state.user_id;
        apiUsersDetail(id).then(res=>{
            if(res.code == 200){
                that.avatar = res.data.avatar;
                that.gender = res.data.gender;
                that.name = res.data.name;

                that.email = res.data.email;
                that.phone = res.data.phone;
                that.phone_86 = res.data.phone_86;
                that.phone_853 = res.data.phone_853;

                that.personal_label = res.data.personal_label;
                that.personal_profile = res.data.personal_profile;
                that.serial_number = res.data.serial_number;
            }else{
                that.$message.error(res.message);
            }
        }).catch(error =>{
            console.log(error);
        })
    }
  }
}
</script>
<style>
/* //修改loading加载时的颜色 */
    .el-loading-spinner .path{
      stroke: rgb(203, 167, 126);
    }
.el-loading-spinner .path{
  stroke:#409eff;
}
.avatar-uploaders{
    width:100%;
    height:100%;
}
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .avatar {
    width: 111px;
    height: 111px;
    display: block;
  }
.el-button{
    width:95px;
}
.el-button--info.is-plain{
    background: #fff;
}
.grace-add-list-btn-text{
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #999;
    width: 100%;  
}
.grace-add-list-btn-icon{
    font-size: 38px;
    height: 38px;
    line-height: 38px;
    margin-bottom: 9px;
    color: #999;
}
.grace-add-list-btn{
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.grace-add-list-items{
    width: 106px;
    height: 106px;
    background: #f6f7f8;
    border-radius: 4px;
}
.grace-form-label_ss{
    padding: 30px 0!important;
    width: 90%;
    margin-left: 9px;
    overflow: hidden;
}
.grace-form-label_1s{
    padding: 30px 0!important;
    width: 10%;
    color: #000000;
    height: 106px;
    font-size: 13px;
    line-height:106px;
    float:left;
}
.grace-button{
    border:none;
    width:100%;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    border-radius: 2px;
    color: #fff;
    background: #C49B6C !important;
}
::-webkit-input-placeholder{
    color:grey;
    font-size:16px;
}
.grace-form-input{
    width: 100%;
    height: 20px;
    outline: none;
    line-height: 20px;
    margin: 10px 0;
    background: none;
    border: 0;
    text-align: right;
    color: #333;
    font-size: 13px;
    text-align: left;
}
.grace-form-label_s{
    width: 350px;
    margin-left: 10px;
    overflow: hidden;
}
.grace-form-label_1{
    text-align: left;
    width: 84px;
    display: block;
    width: 90px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    overflow: hidden;
    color: #000000;
}
.grace-border-b{
    border-bottom: 1px solid #838383;
}
.grace-border{
    border-top: 1px solid #838383;
}
.grace-form-item{
    padding: 5px 0;
    align-items: center;
    display: -webkit-flex;
}
.grace-form{
    margin-top: 5vh;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.grace-body-1{
    padding: 20px;
    width: unset;
    height: auto;
    background: #fff;
    margin-top: -1vh;
    min-height: 90vh;
}
  .skip{
    position: fixed;
    right: 30px;
    bottom: 20px;
    font-size: 14px;
  }
  
</style>